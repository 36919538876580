/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./SkuSelector.vue?vue&type=template&id=2f7c37f3&scoped=true"
import script from "./SkuSelector.vue?vue&type=script&lang=js"
export * from "./SkuSelector.vue?vue&type=script&lang=js"
import style0 from "./SkuSelector.vue?vue&type=style&index=0&id=2f7c37f3&prod&scoped=true&lang=css"
import style1 from "./SkuSelector.vue?vue&type=style&index=1&id=2f7c37f3&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f7c37f3",
  null
  
)

export default component.exports